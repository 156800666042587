import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from "react-router-dom";
import { FaBell, FaUser, FaSignOutAlt } from 'react-icons/fa'; // Icons
import { getUserName, getUserProfilePic } from "../utils/storageUtils";

const Header = () => {
    const userName = getUserName();
    const userProfilePic = getUserProfilePic();
    const location = useLocation(); // Get the current location
    const currentPath = location.pathname; // Get the current path

    const [theme, setTheme] = useState('dark');
    const [showNotification, setShowNotification] = useState(false);
    const [unreadCount, setUnreadCount] = useState(3); // Initial unread notifications
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);

    const notificationRef = useRef(null);
    const profileMenuRef = useRef(null); // Ref for profile menu

    // Fetch the logo image path from the CSS variable
    const getLogoImage = () => {
        const logoImg = getComputedStyle(document.documentElement).getPropertyValue('--logo-img');
        return logoImg.replace(/['"]+/g, '').trim(); // Remove any quotes and extra spaces
    };

    const [logoImg, setLogoImg] = useState(getLogoImage());

    // Logout function
    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.clear();
        window.location.href = "/login";
    };

    // State to handle dropdown visibility
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    // Function to toggle dropdown
    const toggleDropdown = () => {
        setDropdownOpen((prev) => !prev);
        setShowNotification(false); // Close notification dropdown when profile menu opens
    };

    const toggleNotificationDropdown = () => {
        setShowNotification((prev) => !prev);
        setDropdownOpen(false); // Close profile dropdown when notification dropdown opens
        if (!showNotification) {
            setUnreadCount(0); // Reset unread count
        }
    };

    // Dummy notifications data
    const notifications = [
        { id: 1, message: "New course available!", time: "2 minutes ago" },
        { id: 2, message: "Your profile was updated.", time: "10 minutes ago" },
        { id: 3, message: "Reminder: Class starts at 3 PM.", time: "1 hour ago" },
    ];

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
        setLogoImg(getLogoImage()); // Update logo image when the theme changes
    }, [theme]);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
    };

    const handleOutsideClick = (event) => {
        if (
            (notificationRef.current && !notificationRef.current.contains(event.target)) &&
            (profileMenuRef.current && !profileMenuRef.current.contains(event.target))
        ) {
            setShowNotification(false);
            setDropdownOpen(false); // Close profile dropdown if clicked outside
        }
    };

    useEffect(() => {
        if (showNotification || isDropdownOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [showNotification, isDropdownOpen]);

    return (
        <header className="header-border">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <Link className="navbar-brand" to="/dashboard">
                            <img src={`${process.env.PUBLIC_URL}${logoImg}`} width={150} alt="logo" />
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <nav className="navbar navbar-expand-lg">
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav m-auto">
                                    <li className={`nav-item mr-5rem ${currentPath === "/" || currentPath === "/dashboard" ? "active" : ""}`}>
                                        <Link className={`nav-link ${currentPath === "/" || currentPath === "/dashboard" ? "clr-theme-primary" : "clr-black"} font600`} to="/dashboard">Home</Link>
                                    </li>
                                    <li className={`nav-item ${currentPath !== "/" && currentPath !== "/dashboard" ? "active" : ""}`}>
                                        <Link className={`nav-link ${currentPath !== "/" && currentPath !== "/dashboard" ? "clr-theme-primary" : "clr-black"}` } to="/services">Services</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div className="col-md-4 text-end">
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-switch me-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                    checked={theme === 'dark'}
                                    onChange={toggleTheme}
                                />
                                <label className="form-check-label clr-black" htmlFor="flexSwitchCheckDefault">
                                    {theme === 'dark' ? 'Dark Mode' : 'Light Mode'}
                                </label>
                            </div>

                            <div className="me-3 position-relative" ref={notificationRef}>
                                <a href="#" onClick={toggleNotificationDropdown} aria-label="Notifications">
                                    <FaBell size={24} />
                                    {unreadCount > 0 && (
                                        <span className="notification-badge">
                                            {unreadCount}
                                        </span>
                                    )}
                                </a>
                                {showNotification && (
                                    <div className="notification-dropdown">
                                        <ul className="list-unstyled">
                                            {notifications.map((notification) => (
                                                <li key={notification.id} className="notification-item">
                                                    <p>{notification.message}</p>
                                                    <small>{notification.time}</small>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            <div className="profile-menu" ref={profileMenuRef}>
                                <div className="d-flex align-items-center cursor-pointer">
                                    <a
                                        className="d-flex justify-content-center align-items-center rounded-circle text-decoration-none"
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleDropdown();
                                        }}
                                        aria-expanded={isDropdownOpen}
                                    >
                                        <img
                                            src={userProfilePic || "https://cdn-icons-png.flaticon.com/512/4715/4715330.png"}
                                            width="40"
                                            height="40"
                                            alt="User Profile"
                                            className="rounded-circle me-2"
                                        />
                                        <h5 className="mb-0 clr-black">{userName}</h5>
                                    </a>
                                    {isDropdownOpen && (
                                        <div className="dropdown-menu position-absolute end-0 mt-3 p-3 bg-white shadow-lg border rounded-3 show profile-dropdown-align public-chat-bg" style={{ minWidth: '280px' }}>
                                            {/* Menu Items */}
                                            <ul className="list-unstyled m-0">
                                                <li>
                                                    <Link
                                                        to="/user/profile"
                                                        className="dropdown-item profile-dropdown-item d-flex align-items-center gap-3 p-3 text-dark rounded-2 text-decoration-none"
                                                    >
                                                        <i className="fas fa-user text-primary"></i>
                                                        <span className="clr-black">My Profile</span>
                                                    </Link>
                                                </li>

                                                <li className="border-top my-2"></li>

                                                <li>
                                                    <a
                                                        href="#"
                                                        onClick={handleLogout}
                                                        className="dropdown-item profile-dropdown-item d-flex align-items-center gap-3 p-3 text-danger rounded-2 text-decoration-none"
                                                    >
                                                        <i className="fas fa-sign-out-alt"></i>
                                                        <span>Log Out</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;